<template>
    <el-dialog
        custom-class="custom-dialog"
        title="电梯风险等级"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement">
            <div class="header">
                <div class="header-item">
                    <span>电梯名称:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="name"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯注册代码:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="code"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>风险等级:</span>
                    <el-select v-model="riskLevel" filterable clearable placeholder="请选择">
                        <el-option
                        v-for="item in riskLevelOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>


                <el-button type="primary" @click="getTable">查询</el-button>
                <el-button type="primary" @click="reset">重置</el-button>
            </div>

            <div>
                <el-table
                v-loading="loading"
                :data="tableData"
                stripe
                ref="multipleTable"
                height="500px"
                style="width: 100%">
                    <el-table-column type="index" label="序号" min-width="50px" />
                    <el-table-column align="center" prop="code" label="电梯注册代码" min-width="150px" />
                    <el-table-column align="center" prop="name" label="电梯名称" min-width="200px" />
                    <el-table-column align='center' prop="riskLevel" label="风险等级" min-width="150px">
                        <template slot-scope="scope">
							<span>{{getRiskLevelText(scope.row.riskLevel)}}</span>
						</template>
                    </el-table-column>
                    
                </el-table>
                <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
            </div>
        </div>
        <div class="null"></div>

    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
export default {
    components: {
        Pagination,
    },
    props: ['riskType'],
    data() {
        return {
            dialogVisible:true,
            loading:false,
            //表格筛选信息
            name:'',
            code:'',
            riskLevel:'',
            riskLevelOptions:[
                {
                    value:1,
                    label:'Ⅰ级风险'
                },
                {
                    value:2,
                    label:'Ⅱ级风险'
                },
                {
                    value:3,
                    label:'Ⅲ级风险'
                },
                {
                    value:4,
                    label:'Ⅳ级风险'
                },
            ],
            //表格数据及分页选项
            tableData: [],
            total:0,
            size:10,
            current:1,
        }
    },
    computed:{
        params(){
            return {
                current:this.current,
                size:this.size,
                name:this.name,
                code:this.code,
                riskLevel:this.riskLevel
            }
        }
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog')
        },
        reset(){
            this.name=''
            this.code=''
            this.riskLevel=''
            this.getTable()
        },
        // 分页
        dictionarPage(data) {
            this.current = data.page
            this.size = data.limit
            this.getTable()
        },
        getTable(){
            this.loading=true
            this.$http.post('/api/ele/web/elevatorInfo/list', this.params).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data.records
                    this.total=res.data.data.total
                    this.$nextTick(() => {
                        if (this.total > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        }
                    })
                }
                this.loading=false
            })
        },
        getRiskLevelText(riskLevel){
            const arr=['Ⅰ级风险','Ⅱ级风险','Ⅲ级风险','Ⅳ级风险']
            return arr[riskLevel-1]
        },



    },
    created(){
        this.riskLevel = this.riskType
        this.getTable()
    }
}
</script>
<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    // overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}
.header,.header-item{
    display: flex;
}
.header{
    flex-wrap: wrap;
    align-items: flex-start;
}
.header-item{
    margin: 0 20px 20px 0;
    align-items: center;
    span{
        white-space: nowrap;
        margin-right: 8px;
    }
}

.scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0,0,0,0) !important;
}
.scrollElement /deep/ .el-table__fixed-right >>> .el-table__row >>> .el-table__cell:last-child{
    background: #1e2d4f ;
}
</style>