<template>
    <el-dialog
        custom-class="custom-dialog"
        title="维保详情"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        append-to-body
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="">
            <div style="height: 50vh;overflow-y: scroll;" class="scrollElement">
				<dioDetails :dioData="dioDetailsItem"></dioDetails>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button v-focus size="small" type="primary" @click="dioDetails = false">关 闭</el-button>
			</span>
        </div>
        <div class="null"></div>
    </el-dialog>    
</template>
<script>
import dioDetails from '../../dtwb/wbjl/components/dioDetails'
export default {
    props:{
        detailsItem:Object,
        initDioDetailsItem:Object,
    },
    data() {
        return {
            dialogVisible:true,
            dioDetailsItem:this.initDioDetailsItem
        }
    },
    components: {
        dioDetails
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog')
        },
    },
    created(){
      
    }
}
</script>

<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}

</style>